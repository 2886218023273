import { Box, Typography } from '@mui/material'
import UsersTab from './UsersTab'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { getOrgGroupsFn } from 'src/api'

function UsersPage() {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const groupID = queryParams.get('groupID')
  const { orgId } = useParams()
  const navigate = useNavigate()

  const { data: groups } = useQuery({
    queryKey: ['orgGroups', orgId],
    queryFn: () => {
      if (!orgId) return
      return getOrgGroupsFn({ orgId })
    },
    keepPreviousData: true,
  })

  const groupName = groups?.find(
    (item) => item.groupId == Number(groupID)
  )?.groupName

  return (
    <Box pt={5}>
      {groupName && (
        <Box
          sx={{ display: 'flex', cursor: 'pointer', justifyContent: 'start' }}
        >
          <Typography onClick={() => navigate(-1)} color="#666666">
            Groups{' '}
          </Typography>
          <Typography pl={1} color={'#18191A'}>
            {' '}
            / {groupName}
          </Typography>
        </Box>
      )}
      <UsersTab />
    </Box>
  )
}

export default UsersPage
