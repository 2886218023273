import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from '@mui/material'
import { ContainedButton } from 'src/components/Button'
import { DarkLogo } from 'src/components/SymanisLogo'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  __DEV__,
  regExEmail,
  regExPhone,
  testingEmail,
} from 'src/share/constants'
import { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import parsePhoneNumber from 'libphonenumber-js'
import { loginWithEmailFn, loginWithPhoneFn, orgRequestCheckFn } from 'src/api'
import AuthPage from './AuthPage'
import { motion, AnimatePresence } from 'framer-motion'
import { useAuth } from 'src/providers/AuthProvider'
import VideoPlayer from '../components/LoginVideo/LoginVideo'
import { Images } from '../assets'

export default function HomePage() {
  const [email, setEmail] = useState(__DEV__ ? testingEmail : '')
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [open, setOpen] = useState(false)
  const [sessionId, setSessionId] = useState('')
  const [ttr, setTtr] = useState('')
  const userEmail = searchParams.get('userEmail')
  const sessionEmail = localStorage.getItem('last_user')
  const [showContinue, setShowContinue] = useState(false)
  const [isAnimatingOut, setIsAnimatingOut] = useState(false)
  const [verificationCode, setVerificationCode] = useState('')
  const [toggleOpen, setTogleOpen] = useState(() => {
    const storedValue = localStorage.getItem('toggleOpen')
    return storedValue ? JSON.parse(storedValue) : true
  })

  useEffect(() => {
    localStorage.setItem('toggleOpen', JSON.stringify(toggleOpen))
  }, [toggleOpen])

  const currentUrl = window.location.href

  const token = currentUrl.split('/')[3]

  const orgRequestId = currentUrl.split('/')[4]

  const { login } = useAuth()

  const { mutate: validateOrgReqId } = useMutation(
    ({ orgReqId, token }: { orgReqId: string; token: string }) =>
      orgRequestCheckFn(orgReqId, token),
    {
      onSuccess: (data: any) => {
        const newEmail = data?.email?.email

        login(token, (userData) => {
          navigate(`/${userData.id}/personal/settings/profile`, {
            state: { newEmail },
          })
        })
      },
      onError: (error: any) => {
        alert(error.response.data.message)
        navigate('/')
      },
    }
  )

  if (token && orgRequestId) {
    validateOrgReqId({ orgReqId: orgRequestId, token })
  }

  function handleRestSecret() {
    setIsAnimatingOut(true)
    setTimeout(() => {
      navigate('/reset-secrets')
    }, 200)
  }
  const handleToggleVisibility = () => {
    setTogleOpen((prevState: boolean) => !prevState)
  }
  function handleRegister() {
    setIsAnimatingOut(true)
    setTimeout(() => {
      navigate('/register')
    }, 200)
  }
  const { mutate: submitEmailLogin, isLoading: loadingEmail } = useMutation(
    loginWithEmailFn,
    {
      onSuccess: (data) => {
        const sessionId = data.sessionId
        const ttrCookie = data.ttrCookie
        setSessionId(sessionId)
        setTtr(ttrCookie)
        setOpen(true)
      },
      onError: (error: any) => {
        if (error.response.status === 405) {
          alert(
            'You have not yet completed your registration. A link to set up secrets has been sent to your email.'
          )
          return navigate('/')
        }
        alert(error.response.data.message)
        return navigate('/')
      },
    }
  )
  const { mutate: submitPhoneLogin, isLoading: loadingPhone } = useMutation(
    loginWithPhoneFn,
    {
      onSuccess: (data) => {
        const sessionId = String(data.sessionId)
        setSessionId(sessionId)
        setOpen(true)
      },
      onError: (error: any) => {
        if (error.response.status === 405) {
          alert(
            'You need to finish registration process. Please register again.'
          )
          return navigate('/register')
        }
        alert(error.response.data.message)
      },
    }
  )

  useEffect(() => {
    if (
      !userEmail &&
      sessionEmail &&
      sessionEmail.length > 0 &&
      searchParams.get('SAMLRequest')
    ) {
      setShowContinue(true)
    }
  }, [sessionEmail])

  useEffect(() => {
    if (performance.navigation.type === 1 && userEmail) {
      navigate({ pathname: '/' })
    } else if (userEmail) {
      const timeoutId = setTimeout(() => {
        setEmail(userEmail)
        if (regExEmail.test(userEmail)) {
          submitEmailLogin({
            email: userEmail,
            requestSource: window.location.href,
            sourceOrg: searchParams.get('org'),
          })
        }
      }, 1000)

      return () => clearTimeout(timeoutId)
    }
  }, [userEmail, searchParams, submitEmailLogin, navigate])

  async function continueAsOldUser() {
    const email = localStorage.getItem('last_user')
    if (email) {
      submitEmailLogin({
        email: email.toLocaleLowerCase(),
        requestSource: window.location.href,
        sourceOrg: searchParams.get('org'),
      })
    }
  }
  async function handleLogin() {
    if (regExEmail.test(email)) {
      sessionStorage.setItem('last_user', email.toLowerCase())
      submitEmailLogin({
        email: email.toLocaleLowerCase(),
        requestSource: window.location.href,
        sourceOrg: searchParams.get('org'),
      })
      return
    }
    if (regExPhone.test(email)) {
      const parsed = parsePhoneNumber(email)
      if (parsed) {
        const { countryCallingCode, nationalNumber } = parsed
        submitPhoneLogin({
          requestSource: window.location.href,
          sourceOrg: searchParams.get('org'),
          countryCode: countryCallingCode,
          phone: nationalNumber,
        })
      }
      return
    }
    alert('Invalid email.')
  }

  // const { mutate: verifyCode, isLoading: loadingCode } = useMutation(
  //   validateCodeLoginFn,
  //   {
  //     onSuccess: (data) => {
  //       const token = data.data.registerToken

  //       setShowCodeModal(false)
  //       setVerificationCode('')

  //       login(token, (userData) => {
  //         return navigate(`/${userData.id}/personal/settings/profile`)
  //       })
  //     },
  //     onError: (error: any) => {
  //       alert('Invalid code')
  //     },
  //   }
  // )

  // const handleCodeSubmit = () => {
  //   verifyCode({ code: verificationCode, email })
  // }

  // const handleCodeModalClose = () => {
  //   setShowCodeModal(false)
  //   return setVerificationCode('')
  // }

  return (
    <div className="flex flex-col px-[100px] py-20 h-screen bg-hero bg-cover">
      <DarkLogo />
      <AnimatePresence>
        <div className="flex flex-row justify-between">
          <form className="w-[600px] h-[490px] p-[44px] rounded-[24px]  mt-[52px] bg-white overflow-hidden">
            <motion.div
              className="flex flex-col space-y-4"
              initial={{ x: 0 }}
              animate={{ x: isAnimatingOut ? '100%' : 0 }}
              exit={{ x: '100%' }}
              transition={{ duration: 0.2 }}
            >
              <p className="font-bold text-[40px] mb-[32px] font-poppins">
                Your digital identity is our top priority
                <span className="text-red-500">.</span>
              </p>
              {showContinue ? (
                <div className="flex flex-col">
                  <p className="font-bold font-inter text-3xl/normal">
                    Hello, Welcom back<span className="text-red-500">.</span>
                  </p>
                  <p
                    style={{ padding: '10px 0' }}
                    className="font-inter text-1xl/normal"
                  >
                    Sign in as <span className="font-bold">{sessionEmail}</span>
                  </p>
                  <ContainedButton
                    style={{ padding: '10px 0' }}
                    loading={loadingEmail || loadingPhone}
                    onClick={continueAsOldUser}
                    title="Continue"
                  />
                  <Button
                    style={{ paddingTop: '20px' }}
                    color="secondary"
                    onClick={() => setShowContinue(false)}
                  >
                    Switch to different account
                  </Button>
                </div>
              ) : (
                <div className="flex flex-col">
                  <TextField
                    sx={{
                      paddingBottom: 5,
                    }}
                    fullWidth
                    id="emailInput"
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        handleLogin()
                        e.preventDefault()
                      }
                    }}
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    label="Enter your email"
                    InputProps={{
                      sx: {
                        padding: '0px',
                        fontSize: '18px',
                      },
                    }}
                    inputProps={{
                      sx: {
                        padding: '21px 12px',
                      },
                    }}
                    InputLabelProps={{
                      sx: {
                        fontSize: '18px',
                        lineHeight: '35px',
                      },
                    }}
                  />
                  <Button
                    className=" hover:bg-[#FA056C]"
                    style={{
                      padding: '14px 0',
                      borderRadius: '12px',
                      background: '#FB3B8C',
                      fontSize: '20px',
                      fontWeight: 600,
                      color: '#fff',
                    }}
                    disabled={!email}
                    onClick={handleLogin}
                  >
                    {loadingEmail ? (
                      <CircularProgress
                        className="my-1"
                        size={'20px'}
                        color="inherit"
                      />
                    ) : (
                      'Login'
                    )}
                  </Button>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      marginTop: '24px',
                    }}
                  >
                    <Button onClick={handleRegister}>
                      <p style={{ fontSize: '18px', fontWeight: 400 }}>
                        New user?{' '}
                        <span style={{ color: '#FB3B8C' }}>sign up</span>
                      </p>
                    </Button>
                    <Button
                      sx={{ fontSize: '18px', fontWeight: 400 }}
                      onClick={handleRestSecret}
                    >
                      Can't login?
                    </Button>
                  </Box>
                </div>
              )}
            </motion.div>
          </form>
          <div>
            <div className="w-full max-w-[480px] rounded-[24px] mt-[52px] bg-[#66666629] border border-[#8F8B99] backdrop-blur-[80px] overflow-hidden">
              <div
                className={`${toggleOpen ? 'border-b border-[#8F8B99]' : ''}`}
              >
                <div className="p-[16px] flex gap-[12px] items-center">
                  <div className="flex flex-row items-center gap-[12px]">
                    <img src={Images.isStartIcon} alt="startIcon" />
                    <h2 className="font-semibold text-[20px] sm:text-[24px] text-[#FAFAFA]">
                      How to login with Symania
                    </h2>
                  </div>
                  <button
                    onClick={handleToggleVisibility}
                    className="text-[#FAFAFA] bg-transparent border-none ml-auto"
                  >
                    <img
                      src={Images.isArrowIcon}
                      alt="arrowIcon"
                      className={`transition-transform duration-300 ${
                        toggleOpen ? 'rotate-180' : ''
                      }`}
                    />
                  </button>
                </div>
              </div>

              <div
                className={`flex flex-col gap-[12px] transition-all duration-500 ease-in-out ${
                  toggleOpen
                    ? 'opacity-100 max-h-[1000px] px-[20px] sm:px-[30px] py-[16px] sm:py-[20px]'
                    : 'opacity-0 max-h-0 overflow-hidden'
                }`}
              >
                <div>
                  <p className="mb-[12px] font-medium text-[14px] sm:text-[16px] text-[#FAFAFA]">
                    Worried about entering your login details on every website
                    or app?
                  </p>
                  <p className="font-bold text-[14px] sm:text-[16px] text-[#FAFAFA]">
                    Symania is the perfect solution!
                  </p>
                </div>
                <div>
                  <p className="text-[14px] sm:text-[16px] text-[#FAFAFA]">
                    Watch the video to discover how you can:
                  </p>
                  <ul className="text-[14px] sm:text-[16px] text-[#FAFAFA] list-disc pl-[16px] sm:pl-[20px]">
                    <li>Protect your personal information</li>
                    <li>Save valuable time</li>
                    <li>
                      Easily and securely log in to all your online services
                    </li>
                  </ul>
                </div>
                <div className="mt-[16px] sm:mt-[20px]">
                  <VideoPlayer stopVideo={toggleOpen} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </AnimatePresence>
      {open && (
        <AuthPage
          ttr={ttr}
          sessionId={sessionId}
          onClose={() => setOpen(false)}
          open={open}
          email={email}
        />
      )}
      <div className="flex-1" />
      {/* {showCodeModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-md w-full max-w-md mx-4">
            <p className="mb-4 text-gray-700">
              Please enter the 6-digit code sent to your phone number in the
              SMS.
            </p>
            <input
              type="text"
              maxLength={6}
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              placeholder="Enter 6-digit code"
              className="w-full p-2 mb-4 border border-gray-300 rounded-md"
            />
            <div className="flex justify-between">
              <button
                onClick={handleCodeSubmit}
                disabled={loadingCode || verificationCode.length !== 6}
                className="w-[150px] px-4 py-2 text-white bg-[#FB3B8C] rounded "
              >
                {loadingCode ? 'Verifying...' : 'Submit Code'}
              </button>
              <button
                className="w-[150px] px-4 py-2 text-white bg-[#303030] rounded "
                onClick={handleCodeModalClose}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )} */}
    </div>
  )
}
