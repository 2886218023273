import { apiRequest } from '../api-request'
import { ENDPOINTS } from '../constants'

export const getUsersFn = async ({ page, pageSize, query }: GetUsersInput) => {
  const { data } = await apiRequest.get<GetUsersResponse>(ENDPOINTS.USERS, {
    params: {
      ...query,
      page: page + 1,
      limit: pageSize,
    },
  })
  return data
}

export const getUserRolesFn = async () => {
  const { data } = await apiRequest.get<GetUserRolesResponse>(
    ENDPOINTS.USERS + '/roles'
  )
  return data.roles
}

export const getUserStatusesFn = async () => {
  const { data } = await apiRequest.get<GetUserStatusesResponse>(
    ENDPOINTS.USERS + '/statuses'
  )
  return data.statuses
}

export const adminUpdateUserFn = async (updatedUser: AdminUpdateUserInput) => {
  await apiRequest.put(ENDPOINTS.USERS + `/${updatedUser.userID}`, updatedUser)
}

export const deleteUserFn = async (input: DeleteUserInput) => {
  await apiRequest.delete(ENDPOINTS.USERS + `/${input.userId}`)
}

export const unlockUserFn = async (input: DeleteUserInput) => {
  await apiRequest.post(ENDPOINTS.USERS + `/${input.userId}` + '/unlock')
}

export const lockUserFn = async (input: DeleteUserInput) => {
  await apiRequest.post(ENDPOINTS.USERS + `/${input.userId}` + '/lock')
}

export const createUserFn = async (input: CreateUserInput) => {
  await apiRequest.post(ENDPOINTS.USERS, input)
}

export const resendUserActivationEmailFn = async (
  input: ResendActivationEmailInput
) => {
  await apiRequest.post(ENDPOINTS.USERS + '/resend-activate-user-link', input)
}

type GetUsersInput = {
  page: number
  pageSize: number
  query?: any
}

type GetUsersResponse = {
  meta: {
    total: number
  }
  data: any
}

type GetUserRolesResponse = {
  roles: Array<{ id: number; role: string }>
}

type GetUserStatusesResponse = {
  statuses: Array<{ id: number; status: string }>
}

export type AdminUpdateUserInput = {
  groupId: number
  userID: number
  first_name: string
  last_name: string
  country_code: string
  phone_number: string | number
  email: string
  enabled: boolean
  roleID: number
  status: number | string
  sequence_length_fail: number
  symbol_location_fail: number
  combo_fail: number
}

type DeleteUserInput = {
  userId: number | string
}

export type CreateUserInput = {
  first_name: string
  last_name: string
  email: string
  country_code: string
  phone_number: string
}

export type ResendActivationEmailInput = {
  userId: number | string
}
