import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { addEmailFn, updateUserEmailFn, validateEmailFn } from 'src/api'
import { useAuth } from 'src/providers/AuthProvider'
import CodeModal from '../CodeModal'
import { Images } from 'src/assets'
import TextFieldDialog from '../TextFieldDialog'

type UpdateUserEmailFormInputs = {
  email: string
}

interface UpdateUserEmailFormProps {
  email: string
  onSubmit: () => void
}

function UpdateUserEmailForm({ email, onSubmit }: UpdateUserEmailFormProps) {
  const { user } = useAuth()
  const {
    register,
    formState: { isDirty, isValid, errors },
    handleSubmit,
    getValues,
    reset,
    trigger,
  } = useForm<UpdateUserEmailFormInputs>({
    defaultValues: {
      email,
    },
    shouldUnregister: true,
  })
  const [modalOpen, setModalOpen] = useState(false)
  const [emailUniqueStr, setEmailUniqueStr] = useState<string | null>(null)
  const [open, setIsOpen] = useState(false)
  const [value, setValue] = useState('')
  const [dialogFieldName, setDialogFieldName] = useState('')

  const { mutate: addEmail, isLoading: isAddingEmail } = useMutation(
    addEmailFn,
    {
      onSuccess: (uniqueStr) => {
        setEmailUniqueStr(uniqueStr)
        setModalOpen(true)
      },
    }
  )
  const { mutate: validateEmail, isLoading: isValidatingEmail } = useMutation(
    validateEmailFn,
    {
      onSuccess: () => {
        submitUpdateUserEmailForm()
      },
    }
  )
  const { mutate: updateUserEmail, isLoading: isUpdatingEmail } = useMutation(
    updateUserEmailFn,
    {
      onSuccess: () => {
        reset(
          {},
          {
            keepValues: true,
            keepIsValid: false,
            keepTouched: false,
            keepErrors: false,
            keepIsSubmitted: false,
          }
        )
        onSubmit()
        alert('Email updated successfully')
      },
    }
  )

  const submitUpdateUserEmailForm = async () => {
    const isFormValid = await trigger()
    if (!user) return
    updateUserEmail({
      userId: user.id,
      email: getValues('email'),
    })
  }

  const requestAddEmail = async () => {
    addEmail({ email: getValues('email') })
  }

  const closeModal = () => {
    setModalOpen(false)
  }

  const requestValidateEmail = async (code: string) => {
    closeModal()
    if (!emailUniqueStr) return
    validateEmail({ unique: emailUniqueStr, code })
  }

  const handleApply = () => {
    requestAddEmail()
    setIsOpen(false)
  }

  return (
    <>
      <form style={{ maxWidth: 600 }} onSubmit={handleSubmit(requestAddEmail)}>
        <Box sx={{ marginTop: '32px', width: '600px' }}>
          <TextField
            {...register('email', { required: 'Email is required' })}
            label="Personal Email"
            fullWidth
            margin="normal"
            variant="outlined"
            error={errors.email ? true : false}
            helperText={errors.email ? errors.email.message : null}
          />
        </Box>
        <Button
          sx={{
            '&:hover': { backgroundColor: '#111111' },
            width: '140px',
            height: '40px',
            padding: '10px 20px',
            borderRadius: '12px',
            color: 'white',
            marginTop: 2,
            fontSize: 16,
            textTransform: 'none',
            backgroundColor: '#111111',
          }}
          type="submit"
          variant="contained"
          disabled={
            !isDirty ||
            isAddingEmail ||
            isValidatingEmail ||
            isUpdatingEmail ||
            !isValid
          }
        >
          Update
        </Button>
      </form>
      <CodeModal
        screen={'email'}
        isOpen={modalOpen}
        onRequestClose={closeModal}
        onSubmit={requestValidateEmail}
      />
    </>
  )
}

export default UpdateUserEmailForm
