import React, { useRef } from 'react';
import {Images} from "../../assets";

interface VideoPlayerProps {
  stopVideo: boolean;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({ stopVideo }) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  React.useEffect(() => {
    if (!stopVideo && videoRef.current) {
      videoRef.current.pause();
    }
  }, [stopVideo]);

  return (
    <div style={{ textAlign: 'center' }}>
      <div
        style={{
          position: 'relative',
          width: '100%',
          margin: '0 auto',
          borderRadius: '8px',
          overflow: 'hidden',
        }}
      >
        <video
          ref={videoRef}
          src={Images.isVideoSymania}
          controls
          style={{
            width: '100%',
            display: 'block',
          }}
        >
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
};

export default VideoPlayer;
