import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import AuthLayout from 'src/layouts/AuthLayout'
import { useMutation } from 'react-query'
import { reportStolenDeviceFn } from 'src/api'
import { countriesPhoneOptions } from 'src/share/constants'
import { useNavigate } from 'react-router-dom'

type ReportStolenDeviceFormInput = {
  email: string
  countryCode: string
  phone: string
}

function ReportStolenDevicePage() {
  const {
    register,
    handleSubmit,
    formState: { isValid },
    getValues,
    control,
  } = useForm<ReportStolenDeviceFormInput>()
  const {
    mutate: reportStolenDevice,
    isLoading,
    isSuccess: reportSuccess,
    data,
  } = useMutation(reportStolenDeviceFn)
  const navigate = useNavigate()

  const submitResetUserForm = async () => {
    if (!isValid) return

    const { email, countryCode, phone } = getValues()
    reportStolenDevice({ email, countryCode, phoneNumber: phone })
  }

  if (reportSuccess) {
    navigate('/report-stolen-device/answer', {
      state: { token: data.token, questions: data.securityQuestions },
    })
    return null
  }

  return (
    <AuthLayout>
      <Box
        sx={{
          borderRadius: 2,
          maxWidth: '500px',
          margin: '0 auto',
          backgroundColor: 'white',
          overflow: 'hidden',
          padding: 2,
        }}
      >
        <Typography variant="h3" component={'h1'}>
          Reset User
        </Typography>
        <form onSubmit={handleSubmit(submitResetUserForm)}>
          <TextField
            {...register('email', { required: true })}
            label="Email"
            fullWidth
            margin="normal"
            required
          />
          <Controller
            name="countryCode"
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onBlur, onChange, ref } }) => (
              <Autocomplete
                options={countriesPhoneOptions}
                getOptionLabel={(option) => option.label}
                ref={ref}
                onChange={(_, newValue) => {
                  onChange(newValue?.value)
                }}
                onBlur={onBlur}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Country code"
                    margin="normal"
                    required
                  />
                )}
              />
            )}
          />
          <TextField
            {...register('phone', { required: true })}
            label="Phone"
            margin="normal"
            fullWidth
            required
          />
          <Button variant="contained" type="submit" disabled={isLoading}>
            {isLoading ? <CircularProgress /> : 'Submit'}
          </Button>
        </form>
      </Box>
    </AuthLayout>
  )
}

export default ReportStolenDevicePage
