import { apiRequest } from '../api-request'
import { ENDPOINTS } from '../constants'

export const registerUserFn = async ({
  first_name,
  last_name,
  email,
  country_code,
  phone_number,
  userGroupId,
}: RegisterUserInput) => {
  const { data } = await apiRequest.post<RegisterUserResponse>(
    ENDPOINTS.USERS + '/register',
    {
      first_name,
      last_name,
      email,
      country_code,
      phone_number,
      userGroupId,
    }
  )
  return data.id
}

export const resetUserFn = async ({
  email,
  countryCode,
  phone,
}: ResetUserInput) => {
  await apiRequest.post(ENDPOINTS.USERS + '/reset', {
    email,
    countryCode,
    phone,
  })
}

export const logoutUserFn = async () => {
  await apiRequest.post(ENDPOINTS.USERS + '/logout')
}

export const activateGroupUserFn = async ({
  token,
}: ActivateGroupUserInput) => {
  const { data } = await apiRequest.post<ActivateGroupUserResponse>(
    ENDPOINTS.USERS + '/activate-group',
    {
      token,
    }
  )
  return data.orgName
}

export const updateUserSequenceForGroupUserActivationFn = async ({
  token,
  sequence,
}: UpdateUserSequenceForGroupUserActivationInput) => {
  await apiRequest.post(ENDPOINTS.USERS + '/activate-group/sequence', {
    token,
    sequence,
  })
}

export const getUserSecrets = async ({ userId }: GetUserSecretsInput) => {
  const { data } = await apiRequest.get<GetUserSecretsResponse>(
    ENDPOINTS.USERS + `/${userId}/secrets`
  )
  return data
}

export const getSessionAccessFn = async ({ token }: GetSessionAccessInput) => {
  const { data } = await apiRequest.post<GetSessionAccessResponse>(
    ENDPOINTS.USERS + '/get-access',
    {
      token,
    }
  )
  return data
}

export const getUserDetailsFn = async ({ userId }: GetUserDetailsInput) => {
  const { data } = await apiRequest.get<GetUserDetailsResponse>(
    ENDPOINTS.USERS + `/${userId}`
  )
  const groups = data.groups
  const orgs = groups.reduce((currentOrgList: any[], group: any) => {
    if (group.isOrgAdmin) {
      const hasOrg = currentOrgList.find(
        (org) => org.id === group.organization.id
      )
      if (!hasOrg) {
        currentOrgList.push(group.organization)
      }
    }
    return currentOrgList
  }, [])
  return { ...data, orgs }
}

export const updateUserImageFn = async ({
  userId,
  imageFile,
}: UpdateUserImageInput) => {
  const { data } = await apiRequest.post(
    ENDPOINTS.USERS + `/${userId}/image`,
    imageFile
  )
  return data
}

export const deleteUserImageFn = async (userId: string) => {
  await apiRequest.post(ENDPOINTS.USERS + `/${userId}/deleteImage`)
}

export const updateUserPhoneFn = async ({
  userId,
  countryCode,
  phoneNumber,
}: UpdateUserPhoneInput) => {
  const { data } = await apiRequest.post(ENDPOINTS.USERS + `/${userId}/phone`, {
    country_code: countryCode,
    phone: phoneNumber,
  })
  return data
}

export const loginWithPhoneFn = async (
  loginWithPhoneDetails: LoginWithPhoneInput
) => {
  const { data } = await apiRequest.post<LoginResponse>(
    ENDPOINTS.USERS + '/create-session',
    loginWithPhoneDetails
  )
  return data
}

export const loginWithEmailFn = async (
  loginWithEmailDetails: LoginWithEmailInput
) => {
  const { data } = await apiRequest.post(
    ENDPOINTS.USERS + '/create-session',
    loginWithEmailDetails
  )
  return data
}

export const updateUserEmailFn = async ({
  userId,
  email,
}: UpdateUserEmailInput) => {
  const { data } = await apiRequest.post(ENDPOINTS.USERS + `/${userId}/email`, {
    email,
  })
  return data
}

export const updateOrgEmailFn = async ({
  userId,
  orgId,
  email,
}: UpdateOrgEmailInput) => {
  const { data } = await apiRequest.post(
    ENDPOINTS.USERS + `/${userId}/org-email`,
    {
      email,
      orgId,
    }
  )
  return data
}

export const updateUserSymbolFn = async ({
  userId,
  ...updateUserSymbolDetails
}: UpdateUserSymbolInput) => {
  const { data } = await apiRequest.post(
    ENDPOINTS.USERS + `/${userId}/symbol`,
    updateUserSymbolDetails
  )
  return data
}

export const updateUserDetailsFn = async ({
  userId,
  ...updateUserDetails
}: UpdateUserDetailsInput) => {
  const { data } = await apiRequest.post(
    ENDPOINTS.USERS + `/${userId}`,
    updateUserDetails
  )
  return data
}

export const reportStolenDeviceFn = async (input: ReportStolenDeviceInput) => {
  const { data } = await apiRequest.post<ReportStolenDeviceResponse>(
    ENDPOINTS.USERS + `/report-stolen`,
    input
  )
  return data
}

export const answerReportStolenDeviceSecurityQuestionsFn = async (
  input: AnswerReportStolenDeviceSecurityQuestionsInput
) => {
  await apiRequest.post(ENDPOINTS.USERS + `/report-stolen/answer`, input)
}

export const activateUserFn = async (token: string) => {
  const { data } = await apiRequest.post<ActivateUserResponse>(
    ENDPOINTS.USERS + `/activate-user`,
    {
      token,
    }
  )
  return data
}

type RegisterUserInput = {
  first_name: string
  last_name: string
  email: string
  country_code: string
  phone_number: string
  userGroupId?: string
}

type RegisterUserResponse = {
  id: number
}

type ResetUserInput = {
  email: string
  countryCode: string
  phone: string
}

type ActivateGroupUserInput = {
  token: string
}

type ActivateGroupUserResponse = {
  orgName: string
}

type UpdateUserSequenceForGroupUserActivationInput = {
  sequence: number
  token: string
}

type GetUserSecretsInput = {
  userId: number | string
}

type UserSymSettings = {
  matrixSizeH: number
  matrixSizeW: number
  symbolId: {
    id: number
    category: {
      id: number
      name: string
    }
  }
  sequence: number
  minSequence: number
}

type SecurityQuestion = {
  id: number
  question: string
  answer: string
}

type UserQuestions = {
  question1: SecurityQuestion
  question2: SecurityQuestion
  question3: SecurityQuestion
}

type GetUserSecretsResponse = {
  symSettings: UserSymSettings | null
  userSecurityQuestions: UserQuestions | null
  minSequence: number
}

type GetSessionAccessInput = {
  token: string
}

type GetSessionAccessResponse = {
  token: string
}

type GetUserDetailsInput = {
  userId: number | string
}

type GetUserDetailsResponse = {
  image: string | null
  firstName: string
  lastName: string
  countryCode: string
  phoneNumber: number
  email: string
  groups: any[]
}

type UpdateUserImageInput = {
  userId: number | string
  imageFile: any
}

type UpdateUserPhoneInput = {
  userId: number | string
  countryCode: string
  phoneNumber: number | string
}

type BaseLoginInput = {
  requestSource: string
  sourceOrg: string | null
}

type LoginWithPhoneInput = BaseLoginInput & {
  countryCode: string
  phone: string | number
}

type LoginWithEmailInput = BaseLoginInput & {
  email: string
}

type LoginResponse = {
  sessionId: number
}

type UpdateUserEmailInput = {
  userId: number | string
  email: string
}

type UpdateOrgEmailInput = {
  userId: number | string
  orgId: number | string
  email: string
}

type UpdateUserSymbolInput = {
  userId: number | string
  sequence: number
  symbol: number | string
  matrixSizeH?: number
  matrixSizeW?: number
}

type UpdateUserDetailsInput = {
  userId: number | string
  first_name: string
  last_name: string
}

type ReportStolenDeviceInput = {
  email: string
  countryCode: string
  phoneNumber: string | number
}

type ReportStolenDeviceResponse = {
  token: string
  securityQuestions: {
    question1: string
    question2: string
    question3: string
  }
}

type AnswerReportStolenDeviceSecurityQuestionsInput = {
  token: string
  answer1: string
  answer2: string
  answer3: string
}

type ActivateUserResponse = {
  id: number
}
